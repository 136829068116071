import { createSlice, Dispatch } from '@reduxjs/toolkit';
import axios from '../../../utils/axios';
import { IDataConnectorRequest, IDataConnectorRequestState } from '../../../@types/@acs/dataConnector';

// ----------------------------------------------------------------------

const initialState: IDataConnectorRequestState = {
    isLoading: false,
    error: null,
    requests: [],
};

const slice = createSlice({
    name: 'data-request',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },

         // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },

        initialize(state) {
            state.isLoading = initialState.isLoading;
            state.error = initialState.error;
            state.requests = initialState.requests;
        },

        // GET REQUESTS
        getRequestsSuccess(state, action) {
            state.isLoading = false;
            state.requests = action.payload;
            state.error = null;
        },
    }
});

export default slice.reducer;

// Actions
export const {
    initialize
} = slice.actions;

export function getRequests(hubId: string, projectId: string) {
    return async (dispatch: Dispatch) => {
        try {
            dispatch(slice.actions.startLoading());
            const response = await axios.get(`/aps/o/data-connector/v1/accounts/${hubId.slice(2)}/requests`);
            //  /aps/o/data-connector/v1/accounts/{accountId}/requests
            //console.log(response)
            // const response = await axios.get(`/aps/data/accounts/${hubId}/projects/${projectId}/requests?force=true`);

            const list: IDataConnectorRequest[] = response.data.results.map((data: any) => {
                //const params = new URLSearchParams(new URL(data.callbackUrl).search);
                //const useValue = params.get("use");
                let useValue;
                // callbackUrl이 없으면 ACC에서 생성한 데이터 커넥터 (use가 없는 것과 같은 경우)
                if (data?.callbackUrl) {
                    const params = new URLSearchParams(new URL(data.callbackUrl).search);
                    useValue = params.get("use");

                    const projId = projectId.startsWith('b.') ? projectId.substring(2) : projectId;
                    if (data.projectId === projId && useValue === 'pb') {
                        return {
                            id: data.id,
                            isActive: data.isActive,
                            accountId: data.accountId,
                            projectId: data.projectId,
                            createdByName: data.createdBy?.name,
                            createdByEmail: data.createdByEmail,
                            createdByAvatar: data.createdBy?.avatar,
                            createdAt: new Date(data.createdAt),
                            effectiveFrom: new Date(data.effectiveFrom),
                            callbackUrl: data.callbackUrl,
                            use: useValue,
                        } as IDataConnectorRequest;
                    }
                }

                // map 함수가 모든 요소에 대해 반환값을 생성해야 하므로 null을 반환하여 유효하지 않은 데이터를 제거
                return null;
            }).filter((item: null) => item !== null); // 결과 배열에서 유효하지 않은 데이터를 제거하여 유효한 데이터만 남김

            dispatch(slice.actions.getRequestsSuccess(list));
        } catch (error) {
            console.log(error)
            dispatch(slice.actions.hasError(error));
        }
    };
};