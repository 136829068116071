import { createAsyncThunk, createSlice, Dispatch } from '@reduxjs/toolkit';
import axios from '../../../utils/axios';
import { IDataConnectorJob, IDataConnectorJobState, IDataConnectorRequest } from '../../../@types/@acs/dataConnector';
import { RootState } from 'redux/store';


// ----------------------------------------------------------------------

const initialState: IDataConnectorJobState = {
    isLoading: false,
    error: null,
    jobs: [],
};

const slice = createSlice({
    name: 'data-job',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },

         // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },

        initialize(state) {
            state.isLoading = initialState.isLoading;
            state.error = initialState.error;
            state.jobs = initialState.jobs;
        },

        // GET JOBS
        getJobsSuccess(state, action) {
            state.isLoading = false;
            state.jobs = action.payload;
        },
    }
});

export default slice.reducer;

// Actions
export const {
    initialize
} = slice.actions;

// export function getJobs(hubId: string | undefined, projectId: string | undefined, requestId: string | undefined, limit: number, offset: number) {
export function getJobs(request: IDataConnectorRequest | null, limit: number, offset: number) {
    return async (dispatch: Dispatch, getState: () => RootState) => {
        try {
            // 다른 예약 클릭 시 offset 0으로 호출하므로 갱신
            if (offset === 0) {
                dispatch(slice.actions.getJobsSuccess([]));
            }
            if (!request?.accountId || !request?.projectId || !request?.id) {
                dispatch(slice.actions.getJobsSuccess([]));
            }
            else {
                dispatch(slice.actions.startLoading());
                const response = await axios.get(`/aps/o/data-connector/v1/accounts/${request?.accountId}/jobs?limit=${limit}&offset=${offset}`);
                // const response = await axios.get(`/aps/data/accounts/${hubId}/projects/${projectId}/requests/${requestId}/jobs?rforce=true`);

                const list: IDataConnectorJob[] = response.data.results
                    .filter((data: any) => data.requestId === request.id) // 해당 request에 대한 job만 필터링 (account의 전체 job을 가져오려면 이 부분 주석 처리)
                    .map((data: any) => {
                        return {
                            id: data.id,
                            accountId: data.accountId,
                            projectId: data.projectId,
                            requestId: data.requestId,
                            createdAt: new Date(data.createdAt),
                            startedAt: data.startedAt ? new Date(data.startedAt) : null,
                            completedAt: data.completedAt ? new Date(data.completedAt) : null,
                            progress: data.progress,
                            status: data.status,
                            completionStatus: data.completionStatus,
                            totalResults: response.data.pagination.totalResults,
                        } as IDataConnectorJob;
                    });

                // 현재 state 가져오기
                const currentState = getState().job;
                
                // 새로운 배열 만들기 (기존 jobs + list)
                const updatedJobs = [...currentState.jobs, ...list];

                // dispatch
                dispatch(slice.actions.getJobsSuccess(updatedJobs));
            }
        } catch (error) {
            console.log(error);
            dispatch(slice.actions.hasError(error));
        }
    };
};