import { useEffect, useState } from 'react';

import { Outlet, useLocation } from 'react-router-dom';
import { PATH_APP } from 'routes/paths';

// @mui
import { Box } from '@mui/material';
// hooks
import useResponsive from '../../hooks/useResponsive';
// components
import { useSettingsContext } from '../../components/settings';
import Chatbot from 'components/chat-bot';
//
import Main from '../dashboard/Main';
import Header from './header/Header';
import NavMini from '../dashboard/nav/NavMini';
import NavVertical from './nav/NavVertical';
import NavHorizontal from '../dashboard/nav/NavHorizontal';
import config from 'configs/config';

// ----------------------------------------------------------------------

export default function AppLayout() {
  const { pathname } = useLocation();

  const { themeLayout } = useSettingsContext();

  const isDesktop = useResponsive('up', 'lg');

  const [open, setOpen] = useState(false);

  const isNavHorizontal = themeLayout === 'horizontal';

  const isNavMini = themeLayout === 'mini';

  const [chatbotEnabled, setChatbotEnabled] = useState(false); // 챗봇 필터링 버튼 활성화 상태 관리

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // 챗봇 필터링 이벤트 처리
  const handleKeyPress = (event: { ctrlKey: any; key: string; }) => {
    if (event.ctrlKey && event.key === 'Enter') {
      setChatbotEnabled((prev) => !prev); // 활성화/비활성화 상태 변경
    }
  };

  // 챗봇 필터링 키 이벤트를 페이지에 등록
  useEffect(() => {
    window.addEventListener('keydown', handleKeyPress);
    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, []);

  const renderNavVertical = <NavVertical openNav={open} onCloseNav={handleClose} />;

  if (isNavHorizontal) {
    return (
      <>
        <Header onOpenNav={handleOpen} />

        {isDesktop ? <NavHorizontal /> : renderNavVertical}

        <Main>
          <Outlet />
        </Main>

        {/* {pathname === PATH_APP.issue.list && config.apiServerUrl !== 'https://cloud.bimlize.com/api' && <Chatbot />} */}
      </>
    );
  }

  if (isNavMini) {
    return (
      <>
        <Header onOpenNav={handleOpen} />

        <Box
          sx={{
            display: { lg: 'flex' },
            minHeight: { lg: 1 },
          }}
        >
          {isDesktop ? <NavMini /> : renderNavVertical}

          <Main>
            <Outlet />
          </Main>
        </Box>

        {/* {pathname === PATH_APP.issue.list && config.apiServerUrl !== 'https://cloud.bimlize.com/api' && <Chatbot />} */}
      </>
    );
  }

  return (
    <>
      <Header onOpenNav={handleOpen} />

      <Box
        sx={{
          display: { lg: 'flex' },
          minHeight: { lg: 1 },
        }}
      >
        {renderNavVertical}

        <Main>
          <Outlet />
        </Main>
      </Box>

      {/* 챗봇 필터링 */}
      {chatbotEnabled &&
        pathname === PATH_APP.issue.list && config.apiServerUrl !== 'https://cloud.bimlize.com/api' && <Chatbot />}
      {/* pathname === '/app/issue/list' */}
    </>
  );
}
