import { useEffect, useRef, useState } from 'react';

// @mui
import { Box, Button, Fab, IconButton, List, ListItem, ListItemText, TextField, Tooltip, Typography } from '@mui/material';
//
import { Props } from './types';

import Iconify from 'components/iconify';
import { StyledRoot } from 'layouts/login/styles';
import { PartLoadingScreen } from 'components/loading-screen';
import { PATH_APP } from 'routes/paths';
import { useDispatch, useSelector } from 'redux/store';
import { setIssueFilterChats, setResetChats, setFilteredIssues, getAiFilterdIssues } from '../../redux/slices/@acs/issue';
import { IChatMessage } from '../../@types/@acs/issue';
import React from 'react';

// ----------------------------------------------------------------------

export default function Chatbot({}: Props) {
  const dispatch = useDispatch();

  const [openChat, setOpenChat] = useState(false);

  const messagesRef = useRef<IChatMessage[]>([]); // messages 상태를 참조하는 ref 생성

  const chatInterfaceRef = useRef<{ // ChatInterface를 제어하는 ref
    resetMessages: () => void;
    setLoadingState: (state: boolean) => void;
  } | null>(null);

  const handleOpenChat = () => {
    setOpenChat(true);
  };

  const handleResetChat = async () => {
    if (chatInterfaceRef.current) {
      chatInterfaceRef.current.resetMessages(); // ChatInterface의 resetMessages 호출
      chatInterfaceRef.current.setLoadingState(true);
      dispatch(setResetChats());
      await new Promise(resolve => setTimeout(resolve, 250));
      chatInterfaceRef.current.setLoadingState(false);
    }
  };

  const handleCloseChat = () => {
    dispatch(setIssueFilterChats(messagesRef.current)); // messagesRef를 사용하여 현재 messages 상태를 가져와서 Redux 상태 업데이트
    setOpenChat(false);
  };

  // --------------------------------------------------------------------------------------

  // ChatInterface 컴포넌트를 forwardRef로 감싸고 부모 컴포넌트에서 제어 가능한 메서드(resetMessages)를 추가
  const ChatInterface = React.forwardRef((props, ref) => {
    const { issues} = useSelector((state: any) => state.issue);

    const chats = useSelector((state: any) => state.issue.chats as IChatMessage[]);   // const { chats } = useSelector((state: any) => state.issue);

    const [messages, setMessages] = useState<IChatMessage[]>([
      { sender: 'bot', text: '안녕하세요! 어떤 이슈를 찾아드릴까요?' },
    ]);

    React.useImperativeHandle(ref, () => ({
      resetMessages: () => {
        setMessages([{ sender: 'bot', text: '안녕하세요! 어떤 이슈를 찾아드릴까요?' }]);
      },
      setLoadingState: (state: boolean) => {
        setLoading(state); // 부모 컴포넌트에서 loading 상태 변경
      },
    }));

    const [input, setInput] = useState('');

    const [loading, setLoading] = useState(false);

    const messagesEndRef = useRef<HTMLDivElement | null>(null);

    const inputRef = useRef<HTMLInputElement | null>(null); // 입력창 참조 생성

    const handleSend = async () => {
      try {
        if (input.trim() === '') return;

        const userMessage: IChatMessage = { sender: 'user', text: input };
        setMessages((prev) => [...prev, userMessage]);
        setInput('');
        setLoading(true);

        const response = await dispatch(getAiFilterdIssues(input)) as any;
        let botMessage: IChatMessage;
        // 성공 케이스 처리
        if (response.data && response.data.length > 0) {
          const filteredIssues = issues.filter((issue: { id: string }) => response.data.includes(issue.id));
          dispatch(setFilteredIssues(filteredIssues));
          botMessage = { sender: 'bot', text: `요청하신 조건에 부합하는 이슈 ${response.data.length}개를 찾았습니다.` };
        }
        // 에러 응답 처리
        else if (response?.code) {
          switch (response?.code) {
            case 'NOT_FOUND_UNIQUE_KEY':
            case 'NOT_FOUND_RELATED_FIELDS':
            case 'NOT_FOUND_DATA_FILTERED':
              botMessage = { sender: 'bot', text: '죄송하지만, 질문이 명확하지 않습니다. 다시 작성해 주십시오.' };
              break;
            case 'NOT_MATCHING_ISSUE_IDS':
              botMessage = { sender: 'bot', text: '요청하신 이슈를 찾을 수 없습니다.' };
              break;
            default:
              throw new Error('Unknown Code');
          }
        }
        // 그 외 에러 처리
        else {
          throw new Error('Unknown Response');
        }

        setMessages((prev) => [...prev, botMessage]);
      } catch (error) {
        console.error(error);
        const botMessage: IChatMessage = { sender: 'bot', text: '죄송합니다. 응답을 생성하는 데 문제가 발생했습니다.' };
        setMessages((prev) => [...prev, botMessage]);
      } finally {
        setLoading(false);
      }
    };

    const handleKeyPress = (e: { key: string }) => {
      if (e.key === 'Enter') {
        handleSend();
      }
    };

    useEffect(() => {
      if (!loading && inputRef.current) {
        inputRef.current.focus(); // loading이 끝났을 때 입력창에 포커스
      }
    }, [loading]);

    useEffect(() => {
      messagesRef.current = messages;

      if (messagesEndRef.current) {
        const listElement = messagesEndRef.current.parentElement;
        if (listElement) {
          listElement.scrollTop = listElement.scrollHeight;
        }
      }
    }, [messages]);

    useEffect(() => {
      if (chats.length > 1) {
        setMessages(chats);
      }
    }, [chats]);

    useEffect(() => {
      // 컴포넌트가 마운트될 때 실행

      // 기존에 채팅 기록 있을 경우, 불러와서 채팅 메시지 설정
      if (messages.length === 1 && chats.length > 1) {
        setMessages(chats);
      }

      return () => {
        // 컴포넌트가 언마운트될 때 실행
        if (openChat) {
          handleCloseChat();
          // dispatch(setFilteredIssues([])); // 챗봇 필터링된 데이터 초기화 (챗봇을 켰다 껐다 현 페이지에서는 가능하게 하기 위해 주석 처리, 이슈 페이지 자체가 바뀌면 초기화 필요)
        }
      };
    }, []);

    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '45vh',
          // border: '1px solid #ccc',
          // borderRadius: 2,
          // overflow: 'hidden',
          position: 'relative',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            border: '1px solid #ccc',
            borderRadius: 2,
            overflowY: 'auto',
            my: 2,
          }}
        >
          {/* 메시지 목록 */}
          <List
            sx={{
              flexGrow: 1,
              overflowY: 'auto',
              // padding: 2,
            }}
          >
            {messages.map((msg, index) => (
              <ListItem key={index} sx={{ justifyContent: msg.sender === 'user' ? 'flex-end' : 'flex-start' }}>
                <Box
                  sx={{
                    bgcolor: msg.sender === 'user' ? 'primary.main' : 'grey.300',
                    color: msg.sender === 'user' ? 'white' : 'black',
                    borderRadius: 2,
                    p: 1,
                    maxWidth: '70%',
                  }}
                >
                  <ListItemText primary={msg.text} />
                </Box>
              </ListItem>
            ))}
            {/* 스크롤바를 최하단으로 이동시키기 위한 참조 */}
            <div ref={messagesEndRef} />
          </List>
        </Box>

        {loading && (
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: 'rgba(255, 255, 255, 0.5)', // 반투명 배경
              zIndex: 10,
            }}
          >
            <StyledRoot sx={{ position: 'absolute', alignItems: 'center', zIndex: 2 }}>
              <PartLoadingScreen />
            </StyledRoot>
          </Box>
        )}

        {/* 입력창 */}
        <Box sx={{ display: 'flex' }}>
          <TextField
            fullWidth
            variant="outlined"
            placeholder="메시지를 입력하세요..."
            value={input}
            onChange={(e) => setInput(e.target.value)}
            onKeyPress={handleKeyPress}
            disabled={loading}
            inputRef={inputRef}
            autoComplete="off" // 자동완성 비활성화
          />
          <Tooltip title="보내기">
            <Button
              variant="contained"
              color="primary"
              sx={{
                ml: 1,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center', // 아이콘과 텍스트를 수직 및 수평 중앙 정렬
              }}
              disabled={loading}
              onClick={handleSend}
            >
              <Iconify icon="eva:paper-plane-fill" />
              {/* <Iconify icon="eva:paper-plane-fill" sx={{ mr: 1 }} />
              보내기 */}
            </Button>
          </Tooltip>
          {/* <Button variant="contained" color="primary" onClick={handleSend} sx={{ ml: 1 }} disabled={loading}>
            전송
          </Button> */}
        </Box>
      </Box>
    );
  });

  // --------------------------------------------------------------------------------------

  return (
    <>
      {/* 플로팅 액션 버튼 */}
      {!openChat && (
        <Tooltip title="자연어 필터링">
          <Fab
            color="primary"
            aria-label="자연어 필터링"
            onClick={handleOpenChat}
            sx={{
              position: 'fixed',
              bottom: 16,
              right: 16,
              // zIndex: (theme) => theme.zIndex.tooltip + 1, // 이슈 상세 정보 창보다 위에 위치해야 하므로 주석
            }}
          >
            <Iconify icon="fluent:settings-chat-24-regular" />
          </Fab>
        </Tooltip>
      )}

      {openChat && (
        <Box
          sx={{
            position: 'fixed',
            bottom: 16, // FAB 위에 위치
            right: 16,
            width: { xs: '90%', sm: 364 },
            maxWidth: '100%',
            bgcolor: 'background.paper',
            borderRadius: 2,
            boxShadow: 24,
            p: 2,
          }}
        >
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography id="chatbot-modal-title" variant="h6" component="h2">
              자연어 필터링
            </Typography>
            <Box sx={{ display: 'flex', gap: 1, marginLeft: 'auto' }}>
              <Tooltip title="지우기">
                <IconButton onClick={handleResetChat}>
                  <Iconify icon="eva:refresh-fill" />
                </IconButton>
              </Tooltip>
              <Tooltip title="최소화">
                <IconButton onClick={handleCloseChat}>
                  <Iconify icon="eva:minus-fill" />
                </IconButton>
              </Tooltip>
            </Box>
          </Box>
          {/* 채팅 내용 */}
          <Box id="chatbot-modal-description" sx={{ mt: 2 }}>
            {/* 여기에 채팅 컴포넌트 또는 iframe을 통합할 수 있습니다 */}
            <Typography>원하시는 이슈를 검색해주세요!</Typography>
            {/* 채팅 인터페이스 */}
            <ChatInterface ref={chatInterfaceRef} />
          </Box>
        </Box>
      )}
    </>
  );
}
