import axios from 'axios';
// config
// import { HOST_API_KEY } from '../config-global';
import config from '../configs/config';

// ----------------------------------------------------------------------

const axiosInstance = axios.create({ baseURL: config.apiServerUrl, withCredentials: true });

axiosInstance.interceptors.response.use(
  (response) => response,
  // (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong')
  (error) => {
    if (error.response) {
      const { status, statusText, data } = error.response;
      const code = data?.Error || 'Unknown Code';
      const message = data?.Message || 'Unknown Message';
      return Promise.reject({ status, statusText, code, message }); // 에러 응답의 상태 코드와 상태 텍스트를 반환
    } else {
      return Promise.reject('Something went wrong');
    }
  }
);

export default axiosInstance;
